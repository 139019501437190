<template>
    <b-table
        :busy="collection.loading && collection.models.length <= 0"
        :fields="fields"
        :items="collection.models"
        hover
        responsive
        no-local-sorting
        :sort-by.sync="collection.sortBy"
        :sort-desc.sync="collection.sortDesc"
        table-class="table-click"
        @row-clicked="$emit('click-row', $event)"
    >
        <!-- Table busy state -->
        <template #table-busy>
            <spinner-block />
        </template>
    </b-table>
</template>

<script>
import {Groups} from '@/models/vue-mc/Group';

export default {
    name: 'GroupsTable',
    props: {
        collection: {
            type: Groups,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true,
                    label: this.$t('groups.fields.name'),
                    tdClass: 'font-weight-bold',
                },
                {
                    key: 'groupUsersCount',
                    sortable: true,
                    label: this.$t('groups.fields.active'),
                },
                {
                    key: 'slots',
                    sortable: true,
                    label: this.$t('groups.fields.membersLimit'),
                },
                {
                    key: 'createdAt',
                    sortable: true,
                    label: this.$t('common.fields.createdAt'),
                    formatter: value => this.$format(value, {momentTimeAgo: true}),
                },
            ],
        };
    },
};
</script>
