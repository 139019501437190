<template>
    <div>
        <portal to="layout-default-header">
            <header-index :title="$t('groups.index.title')">
                <div class="mr-3">
                    <input-search v-model="collection.searchQuery" />
                </div>

                <template #create-new>
                    <b-button
                        variant="dark"
                        @click="$router.push(collection.getLocation())"
                    >
                        {{ $t('groups.index.addOrganisation') }}
                    </b-button>
                </template>
            </header-index>
        </portal>

        <groups-table
            v-infinite-scroll.document="fetchCollection"
            :collection="collection"
            @click-row="onClickRow"
        />
    </div>
</template>

<script>
import QueryableCollection from '@/components/common/mixins/QueryableCollection';
import {Groups} from '@/models/vue-mc/Group';
import Redirect from '@/library/Redirect';
import GroupsTable from '@/components/groups/Table';
import HeaderIndex from '@/components/layouts/HeaderIndex';
import InputSearch from '@/components/common/form/InputSearch';
import ShowModalConfirm from '@/components/common/mixins/ShowModalConfirm';

export default {
    name: 'GroupsIndex',
    components: {InputSearch, GroupsTable, HeaderIndex},
    mixins: [ShowModalConfirm, QueryableCollection],
    data() {
        return {
            collection: new Groups(),
        };
    },
    async mounted() {
        await this.fetchCollectionUntilScrollable();
    },
    methods: {
        onClickRow(item) {
            if (!item.id) {
                return;
            }

            Redirect.to(item.getLocation());
        },
    },
};
</script>
